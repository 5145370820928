import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
// import * as css from "@css/projects/p-index-hero.module.styl"
// import { TransitionLink } from "../atoms/Link"
// import Tags from "@projects/Tags"
// import IconLimited from "@atoms/IconLimited"
import Border from "@atoms/Border"
import { List } from "@projects/List"

export default function IndexStage()
{
    const q = useStaticQuery(graphql`
        query {
            card_white : file(relativePath: { eq: "Common__img__card-white.png" }) { childImageSharp {
                ...Card
            } }
            card_silver : file(relativePath: { eq: "Common__img__card-silver.png" }) { childImageSharp {
                ...Card
            } }
            card_gold : file(relativePath: { eq: "Common__img__card-gold.png" }) { childImageSharp {
                ...Card
            } }
            card_plutina : file(relativePath: { eq: "Common__img__card-plutina.png" }) { childImageSharp {
                ...Card
            } }
            card_legend : file(relativePath: { eq: "Common__img__legend.png" }) { childImageSharp {
                ...Card
            } }
        }
    `)
    return(
        <Section id="stage">
            <SectionHeader
                title="Stage"
                text="会員ステージ"
            />
            <SectionContent>
                <section>
                    <p>
                        城田優を応援すればするほど楽しみが増えていくサービスが登場しました。ずっと応援してくれているラブピ会員の皆様に喜んでいただきたい、そして「ありがとう」の感謝の気持ちを込めて、城田優自ら発案したサービスです。
                    </p>
                    <p>
                        継続年数が増えると会員ステージがひとつずつアップし、各ステージに応じた特別な画像、限定アイテムをお届けいたします。<br />
                        ステージは、ホワイトステージからダイヤモンドステージまでの計5ステージをご用意いたしました。各ステージの特典内容に関しては、下記をご覧ください。
                    </p>
                    <p>
                        ※各ステージの特典内容は、急遽変更となる場合があります。<br />
                        ※一度退会されますと、継続年数はリセットとなり、それまでに取得された特典もご覧いただけなくなります。予めご了承ください。
                    </p>
                </section>
                <Border/>
                <List data={[
                    {
                        img: q.card_white,
                        title: 'ホワイト / 1年',
                        text: '・記念スペシャル画像'
                    }, {
                        img: q.card_silver,
                        title: 'シルバー / 3年',
                        text: `・記念スペシャル画像<br>
                        ・オリジナルイラスト待受画像<br>
                        ・毎日聴きたくなるボイス`
                    }, {
                        img: q.card_gold,
                        title: 'ゴールド / 5年',
                        text: `・記念スペシャル画像<br>
                        ・オリジナルイラスト待受画像<br>
                        ・毎日聴きたくなるボイス<br>
                        ・限定オリジナルソング<br>
                        ・5年記念ゴールド会員証発行`
                    }, {
                        img: q.card_plutina,
                        title: 'プラチナ / 7年',
                        text: `・記念スペシャル画像<br>
                        ・オリジナルイラスト待受画像<br>
                        ・毎日聴きたくなるボイス<br>
                        ・限定オリジナルソング<br>
                        ・記念スペシャルグッズ<br>
                        ・ラッキーセブン！プラチナ会員限定イベント`
                    }, {
                        img: q.card_legend,
                        title: 'ダイヤモンド / 10年以上',
                        text: `・記念スペシャル画像<br>
                        ・オリジナルイラスト待受画像<br>
                        ・毎日聴きたくなるボイス<br>
                        ・限定オリジナルソング<br>
                        ・10年記念スペシャル会員証発行<br>
                        ・ダイヤモンド会員限定スペシャルコンサート<br>
                        ・メモリアルデジタルフォトギャラリー（※）<br>
                        <br>
                        （※）お客様が、ラブピ会員になってから10年間の未公開秘蔵カットをご覧いただけます。`
                    }
                ]} />
            </SectionContent>
            {/* <SectionMoreLink
                title="JOIN US"
                text="新規登録はこちら"
                to="#"
            /> */}
        </Section>
    )
}
