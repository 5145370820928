import React from "react"
import * as css from "@css/projects/p-column.module.styl"
// import Tags from "@projects/Tags"
// import { Image } from "@atoms/Image"

function Column2({ children }) {
    return (
        <div className={css.pColumn2}>
            { children}
        </div>
    )
}
export { Column2 }