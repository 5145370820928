import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
// import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
// import * as css from "@css/blocks/b-index-about.module.styl"
// import { TransitionLink } from "../atoms/Link"
// import Border from "@atoms/Border"
import Hero from "@blocks/IndexHero"
import TopNews from "@blocks/TopNews"
import TopBanner from "@blocks/IndexBanner"
import About from "@blocks/IndexAbout"
import Limited from "@blocks/IndexLimited"
import Caution from "@blocks/IndexCaution"
import Membership from "@blocks/IndexMembership"
import MembershipFee from "@blocks/IndexMembershipFee"
import Stage from "@blocks/IndexStage"
import Login from "@blocks/IndexLogin"
// import YULetter from "@blocks/IndexYULetter"

export default function IndexBlock()
{
    return(
        <main>
            <Hero />
            <TopBanner />
            <TopNews />
            <About />
            <MembershipFee />
            {/* <Limited /> */}
            <Caution />
            <Membership />
            <Stage />
            {/* <YULetter /> */}
            <Login />
        </main>
    )
}
