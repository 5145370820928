import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
// import * as css from "@css/blocks/b-index-hero.module.styl"
// import { TransitionLink } from "../atoms/Link"
import Tags from "@projects/Tags"
// import IconLimited from "@atoms/IconLimited"
import Border from "@atoms/Border"
import { Grid } from "@projects/Grid"
import { TransitionLink } from "../atoms/Link"

export default function IndexMembership()
{
    const q = useStaticQuery(graphql`
        query {
            grid_1 : file(relativePath: { eq: "IndexMembership__img__01.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
            grid_2 : file(relativePath: { eq: "IndexMembership__img__02.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
            grid_3 : file(relativePath: { eq: "IndexMembership__img__03.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
            grid_4 : file(relativePath: { eq: "IndexMembership__img__04.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
            grid_5 : file(relativePath: { eq: "IndexMembership__img__04.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
        }
    `)
    return(
        <Section id="membership">
            <SectionHeader
                title="Membership"
                text="会員特典"
            />
            <SectionContent>
                <Grid data={
                    [
                        {
                            label: 'benefits 01',
                            title: 'チケット先行予約',
                            img: q.grid_1,
                            tags: [],
                            text: <div>
                                出演する舞台・ミュージカルやイベントのチケット先行予約にお申し込みいただけます。<br/>
                                ※公演によっては先行予約の受付が行えない場合がございます。<br/>
                                ※お申し込み多数の場合は、抽選となります。
                            </div>
                        }, {
                            label: 'benefits 02',
                            title: '会員限定コンテンツ',
                            img: q.grid_2,
                            tags: [],
                            text: <div>
                                『LOVE＆PEACE』でしか見ることのできない撮影現場での様子、お仕事の裏側などをオフショット写真や動画でお届けします！<br/>
                                また、インタビューや公演レポートを掲載します。
                            </div>
                        }, {
                            label: 'benefits 03',
                            title: '動画配信＆会員限定生配信',
                            img: q.grid_3,
                            tags: [],
                            text: <div>
                                城田優がみなさんからの質問や相談にお答えする動画コンテンツ「YUtoYOU」を配信します！〔月1回〕<br/>
                                そして、会員限定の生配信もあり！リアルタイムに繋がれます！〔不定期〕
                            </div>
                        }, {
                            label: 'benefits 04',
                            title: '会員Stage＆デジタル会員証',
                            img: q.grid_4,
                            tags: [],
                            text: <div>
                                会員Stageは、城田優本人の発案で継続年数が増えると会員ステージがひとつずつアップし、各ステージに応じた特別な画像や限定アイテムをお届けします。そして、デジタル会員証はステージに応じてデザインが変わります！<br/>
                                会員Stageの詳細は、<TransitionLink href="/" scroll="stage">コチラ</TransitionLink>からご確認ください。
                            </div>
                        },
                        // {
                        //     label: 'benefits 05',
                        //     title: '会報「LOVE＆PEACE」SpecialBook発行	',
                        //     img: q.grid_5,
                        //     tags: [],
                        //     text: '年に1回会報を発行〔時期は不定期〕<br>1年間で「LOVE＆PEACE」サイトにて配信したコンテンツのまとめと撮りおろしビジュアルを納めたSpecialBookを発行します！'
                        // }, {
                        //     label: '',
                        //     title: '',
                        //     img: false,
                        //     tags: false,
                        //     text: ''
                        // },
                    ]
                }/>
                <section>
                    <h3 className={sectionCss.aTitle1}>
                        入会特典
                    </h3>
                    {/* <div className={sectionCss.aTags}>
                        <Tags data={["年間","3ヶ月","無料"]}/>
                    </div> */}
                    <p>
                        限定グッズプレゼント！〔入会時のみ〕
                    </p>
                </section>
                {/* <section>
                    <h3 className={sectionCss.aTitle1}>
                        デジタル会員証
                    </h3>
                    <div className={sectionCss.aTags}>
                        <Tags data={["年間","3ヶ月","無料"]}/>
                    </div> 
                    <p>
                        ファンクラブ会員の証として、デジタル会員証を表示！
                    </p>
                </section> */}
                <Border/>
                <section>
                    <h3 className={sectionCss.aTitle1}>
                        バースデーメッセージ
                    </h3>
                    {/* <div className={sectionCss.aTags}>
                        <Tags data={["年間"]} />
                    </div> */}
                    <p>
                        お誕生日当日、城田優からのお祝いメッセージをメールにてお届け！
                    </p>
                </section>
                <Border />
                <section>
                    <h3 className={sectionCss.aTitle1}>
                        番組観覧・イベントご招待
                    </h3>
                    {/* <div className={sectionCss.aTags}>
                        <Tags data={["年間"]} />
                    </div> */}
                    <p>
                        出演するテレビ番組やイベント等の観覧へご応募いただけます。
                    </p>
                </section>
                <Border />
                <section>
                    <h3 className={sectionCss.aTitle1}>
                        メールマガジン
                    </h3>
                    {/* <div className={sectionCss.aTags}>
                        <Tags data={["年間", "3ヶ月", "無料"]} />
                    </div> */}
                    <p>
                        城田優の最新情報をメールにて配信！〔不定期〕
                    </p>
                </section>
                <Border />
                <section>
                    <h3 className={sectionCss.aTitle1}>
                        ファンクラブ会員限定イベント
                    </h3>
                    {/* <div className={sectionCss.aTags}>
                        <Tags data={["年間", "3ヶ月"]} />
                    </div> */}
                    <p>
                        ※お申し込み多数の場合は、抽選となります。
                    </p>
                </section>
                <Border />
                <section>
                    <h3 className={sectionCss.aTitle1}>
                        ファンクラブ会員限定グッズ販売
                    </h3>
                    {/* <div className={sectionCss.aTags}>
                        <Tags data={["年間", "3ヶ月"]} />
                    </div> */}
                </section>
            </SectionContent>
            {/* <SectionMoreLink
                title="JOIN US"
                text="新規登録はこちら"
                to="#"
            /> */}
        </Section>
    )
}
