import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
// import * as css from "@css/blocks/b-index-hero.module.styl"
// import { TransitionLink } from "../atoms/Link"
import Tags from "@projects/Tags"
import IconLimited from "@atoms/IconLimited"
import Border from "@atoms/Border"

export default function IndexCaution()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexAbout__img__bg.jpg" }) { childImageSharp {
                ...Background
            } }
            icon_lmited : file(relativePath: { eq: "Common__icn__limited.svg" }) {
                publicURL
            }
        }
    `)
    return(
        <Section id="caution">
            <SectionHeader
                title="Caution"
                text="注意事項"
            />
            <SectionContent>
                <section>
                    <p>
                        ※ご入会・ファンクラブサイトの閲覧は、パソコン・スマートフォン・タブレットからのみとなります。フィーチャーフォン（ガラケー）は対応しておりませんので、予めご了承ください。<br/>
                        ※「会員規約」、「チケット規約」をよくご確認いただき、ご了承の上ご入会くださいますようお願い申し上げます。<br />
                        ※入会のお手続きをしていただいた時点で「会員規約」、「チケット規約」に同意されたものといたします。<br />
                        ※入会特典は、入会後約1ヶ月～1ヶ月半後にお届けいたします。入会時期によっては前後することがございます。予めご了承ください。なお、初回入会特典の発送は、2022年1月より順次行います。発送が完了次第、改めてお知らせいたしますので、お待ちください。<br />
                        ※新規登録は身分証明書と同様の正式な表記でお願いいたします。<br />
                        ※会員期限は、ご入金をいただいた日の翌月1日から1年後です。<br />
                        例) 2021年9月25日ご入金→会員期限：2021年10月1日～ 2022年9月30日<br />
                        ※すべて日本語でのサービス提供となります。また、ご入会は日本国内に発送物の受取先があること、日本円決済が条件となります。
                    </p>
                </section>
            </SectionContent>
            {/* <SectionMoreLink
                title="JOIN US"
                text="新規登録はこちら"
                to="#"
            /> */}
        </Section>
    )
}
