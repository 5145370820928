import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
// import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
import * as css from "@css/blocks/b-index-login.module.styl"
import { TransitionLink } from "@atoms/Link"
// import Tags from "@projects/Tags"
// import IconLimited from "@atoms/IconLimited"
// import Border from "@atoms/Border"
// import { List } from "@projects/List"
import { loginHandler, registerHandler, clickLoginHandler} from "@utility/UrlData"

export default function IndexLogin()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "Common__img__joinus.png" }) {
                publicURL
                childImageSharp {
                    fluid {
                        src
                    }
                }
            }
        }
    `)
    let loginText = q.bg.publicURL
    return(
        <section className={css.pIndexLogin} id="login">
            { q.bg &&
                <div className={css.pIndexLogin__bg}>
                <div style={{ backgroundImage: `url(${loginText})`}}/>
                <div style={{ backgroundImage: `url(${loginText})` }} />
                <div style={{ backgroundImage: `url(${loginText})` }} />
                </div>
            }
            <div className={css.pIndexLogin__inner}>
                <div className={css.aButtons}>
                    <button className={css.aButtonRegister} onClick={registerHandler}>新規登録はこちら</button>
                    <button className={css.aButtonLogin} onClick={clickLoginHandler}>ログインはこちら</button>
                </div>
            </div>
        </section>
    )
}
