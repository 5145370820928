// extracted by mini-css-extract-plugin
export var aButton1 = "Wd";
export var aButton2 = "Xd";
export var aButton3 = "Yd";
export var aButton4 = "Zd";
export var aButton5 = "_d";
export var aButtonHeaderLogin = "ae";
export var aButtonHeaderRegister = "be";
export var aButtonBig = "ce";
export var aButtonRegister = "de";
export var aButtonLogin = "ee";
export var aButtonContinuation = "fe";
export var icon = "ge";
export var aButtonHeaderSpLogin = "he";
export var pIndexLogin = "ie";
export var pIndexLogin__inner = "je";
export var pIndexLogin__bg = "ke";
export var bgSlider = "le";
export var aButtons = "me";
export var animationMarquee = "ne";