import React from "react"
import * as css from "@css/projects/p-tags.module.styl"

function Tags({ data, isCenter }) {
    return (
        <div className={css.pTags} style={isCenter && {textAlign: 'center'}}>
            <ul>
                {data.map((val, i) => {
                    return <li key={i}>{val}</li>
                })}
            </ul>
        </div>
    )
}
export default Tags