import React from "react"
import * as css from "@css/projects/p-list.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"

function List({ data }) {
    return (
        <div className={css.pList}>
            <ul>
                {data.map(({img, title, text}, i) => {
                    return (
                        <li key={i}>
                            <figure className={css.aImage}>
                                <Image data={img}/>
                            </figure>
                            <div className={css.aDetail}>
                                <p className={css.aTitle} dangerouslySetInnerHTML={{ __html: title }}/>
                                <p className={css.aText} dangerouslySetInnerHTML={{__html: text}}/>
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export { List }