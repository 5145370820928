import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink, sectionCss} from "@layouts/Section"
import * as css from "@css/blocks/b-index-about.module.styl"
import { TransitionLink } from "../atoms/Link"
import Border from "@atoms/Border"
import { loginHandler, registerHandler } from "@utility/UrlData"

export default function IndexAbout()
{
    const q = useStaticQuery(graphql`
        query {
            bg : file(relativePath: { eq: "IndexAbout__img__bg.jpg" }) {
                publicURL
            }
            main : file(relativePath: { eq: "IndexAbout__img__main.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
        }
    `)
    return(
        <div className={css.bIndexAbout} id="about">
            <div className={css.bIndexAbout__bg} style={{ backgroundImage: `url(${q.bg.publicURL})` }} />
            <div className={css.bIndexAbout__inner}>
                <Section isMargin={false}>
                    <SectionHeader
                        title="About"
                        text="ファンクラブについて"
                    />
                    <SectionContent>
                        <section>
                            <p>
                                Welcome to brand new “LOVE & PEACE”!!
                            </p>
                            <p>
                                2011年に発足したオフィシャルファンクラブ「Love & Peace」ですが、2021年9月より、今まで以上に皆様にお楽しみいただけるコンテンツを用意し、OFFICIAL FAN CLUB『LOVE&PEACE』として新たなスタートを切りました。
                            </p>
                            <p>
                                『LOVE&PEACE』（通称：ラブピ）では、ファンクラブでしか手にいれることの出来ない”城田優”情報を発信。「YU to YOU」と名付けた城田優から皆さんへのメッセージコンテンツ、SNSでは見ることの出来ない特別な写真や動画の配信、出演する舞台・コンサート等のチケットをどこよりも早く手にいれることが出来る先行受付の実施、そして、城田優本人の発案で継続年数による会員のステージランクアップ。常に楽しみを届けていきたい、応援してくださる皆様に感謝の気持ちを届けたい、そんな気持ちを込めて、応援期間に応じて楽しみが増えていく形を作りました。
                            </p>
                            <p>
                                城田優のモットーである”ラブ＆ピース”の想いを届け、感じていただける特別な場所『ラブピ』で、ファンの皆様とともに素敵な時間を過ごせればと思います。
                                <br />
                                ― Make love & peace together.  
                            </p>
                            <TransitionLink className={sectionCss.aButton3} to="/how-to-join-2022/">入会の流れ</TransitionLink>
                        </section>
                        <Border/>
                        <figure className={sectionCss.aFullImage}>
                            <Image data={q.main} alt="LOVE & PEACE YU SHIROTA" />
                        </figure>
                    </SectionContent>
                    <SectionMoreLink
                        title="JOIN US"
                        text="新規登録はこちら"
                        to={registerHandler}
                    />
                </Section>
            </div>
        </div>
    )
}
