import React from "react"
import * as css from "@css/projects/p-grid.module.styl"
import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"

function Grid({ data }) {
    return (
        <div className={css.pGrid}>
            <ul>
                {data.map(({label,title,img,text,tags}, i) => {
                    return (
                        <li key={i}>
                            <p className={css.aLabel}>{label}</p>
                            <p className={css.aTitle} dangerouslySetInnerHTML={{ __html: title }}/>
                            { img && <figure className={css.aImage}>
                                <Image data={img}/>
                            </figure> }
                            { tags && <div className={css.aGrid}>
                                <Tags data={tags} isCenter/>
                            </div>}
                            <div className={css.aText}>
                                {text}
                            </div>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
export { Grid }