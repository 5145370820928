import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { Image } from "@atoms/Image"
// import { StaticImage } from "gatsby-plugin-image"
import { Section, SectionHeader, SectionContent, SectionMoreLink,sectionCss} from "@layouts/Section"
// import * as css from "@css/blocks/b-index-hero.module.styl"
// import { TransitionLink } from "../atoms/Link"
// import { MinScreen } from "@hooks/useMediaQuery"
// import Tags from "@projects/Tags"
import { Column2 } from "@projects/Column"
// import IconLimited from "@atoms/IconLimited"
import Border from "@atoms/Border"

export default function IndexMembership()
{
    const q = useStaticQuery(graphql`
        query {
            grid_1 : file(relativePath: { eq: "IndexMembership__img__01.jpg" }) { childImageSharp {
                ...Thumbnail
            } }
        }
    `)
    return(
        <Section id="membershipfee">
            <SectionHeader
                title="Membership fee"
                text="会費"
            />
            <SectionContent>
                <Column2>
                    <section>
                        <h3 className={sectionCss.aTitle1}>
                            新規入会
                        </h3>
                        <p>
                            ⚫︎入会金：500円（税込）<br/>
                            ⚫︎年会費：5,500円（税込）<br/>
                            ※別途事務手数料220円（税込）がかかります。
                        </p>
                    </section>
                    <div className="tb-sp">
                        <Border/>
                    </div>
                    <section>
                        <h3 className={sectionCss.aTitle1}>
                            継続
                        </h3>
                        <p>
                            ⚫︎年会費：5,500円（税込）<br/>
                            ※別途事務手数料220円（税込）がかかります。
                        </p>
                        
                    </section>
                </Column2>
            </SectionContent>
            {/* <SectionMoreLink
                title="JOIN US"
                text="新規登録はこちら"
                to="#"
            /> */}
        </Section>
    )
}
