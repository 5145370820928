import * as React from "react"
import { graphql, navigate} from "gatsby"
import IndexBlcok from "@blocks/Index"
import Seo from "@atoms/Seo"
function IndexPage({data})
{
    // const [userLogin] = useRecoilState(userLoginState)
    // const [login, setLogin] = React.useState(false)
    // React.useEffect(()=>{
    //     setLogin(userLogin)
    // },[userLogin])

    return(
        <>
            <Seo/>
            <IndexBlcok/>
        </>
    )
}

export const pagerQuery = graphql`
    fragment Background on ImageSharp {
        gatsbyImageData(
            width: 1440
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
    fragment Thumbnail on ImageSharp {
        gatsbyImageData(
            width: 1440
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
    fragment BigCard on ImageSharp {
        gatsbyImageData(
            layout: FULL_WIDTH
            width: 515
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
    fragment Card on ImageSharp {
        gatsbyImageData(
            layout: FULL_WIDTH
            width: 230
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            quality: 100
        )
    }
`
export default IndexPage
